import axios from 'axios';

export const fetchShoplineCancelledOrders = (brandId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/orders/cancelled`);

export const fetchShoplineReundingOrders = (brandId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/orders/refunding`);

export const fetchShoplineLogisticsFailedOrders = (brandId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/orders/delivery-failed`);

export const fetchShoplineLogisticsReversedOrders = (brandId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/orders/delivery-reversed`);

export const fetchShoplineLogisticsFailedOrdersByCy = (brandId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/orders/cy/delivery-failed`);

export const fetchShoplineLogisticsFailedOrdersByBi = (brandId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/orders/bi/delivery-failed`);
