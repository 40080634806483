import axios from 'axios';

export const updateProductTags = (
  brandId: number,
  productId: number,
  tagIds: number[]
) => {
  return axios.post(
    `/api/brands/${brandId}/products/${productId}/tags`,
    tagIds
  );
};
