import axios from 'axios';

export const updateProductLogistics = (
  brandId: number,
  productId: number,
  logisticsIds: number[]
) => {
  return axios.post(
    `/api/brands/${brandId}/products/${productId}/logistics_methods`,
    logisticsIds
  );
};
