import axios from 'axios';
import { ProductECSku, ProductECSkuParams } from 'types';

export const fetchProductECSku = (brandId: number, productId: number) => {
  return axios.get<ProductECSku[]>(
    `/api/brands/${brandId}/products/${productId}/ec_skus`
  );
};

export const updateProductECSku = (
  brandId: number,
  productId: number,
  params: ProductECSkuParams[]
) => {
  return axios.post(
    `/api/brands/${brandId}/products/${productId}/ec_skus`,
    params
  );
};
