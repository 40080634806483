import axios from 'axios';
import { getYear } from 'date-fns';
import {
  SalesGoal,
  StatisticsMetric,
  TopProduct,
  TotalStatisticsMetric,
} from 'types';

import { getRealMonth } from '../../utility/date';

export const fetchLineGiftBrandSalesAmount = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(`/api/line/brands/${brandId}/statistics/amount`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchLineGiftBrandSalesOrders = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(`/api/line/brands/${brandId}/statistics/orders`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchLineGiftBrandSkuStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(`/api/line/brands/${brandId}/statistics/skus`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchLineGiftBrandECSkuStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(
    `/api/line/brands/${brandId}/statistics/ec_skus`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchLineGiftBrandRevenueStatistics = (
  brandId: number,
  currentMonth: Date
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/line/brands/${brandId}/statistics/revenue`,
    {
      params: {
        year: getYear(currentMonth),
        month: getRealMonth(currentMonth),
        timezone_offset: 8 * 3600,
      },
    }
  );

export const fetchLineGiftBrandSalesGoal = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<SalesGoal>(`/api/line/brands/${brandId}/statistics/sales-goal`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchLineGiftBrandTopProducts = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TopProduct[]>(
    `/api/line/brands/${brandId}/statistics/top-products`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchLineGiftBrandOrderHourCountStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/line/brands/${brandId}/statistics/order-hour-count`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );
