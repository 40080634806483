import axios from 'axios';

export const fetchShoplineAddonsProducts = (brandId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/addons`);

export const fetchShoplineAddonsProduct = (
  brandId: number,
  productId: number
) => axios.get(`/api/shopline/brands/${brandId}/addons/${productId}`);

export const updateShoplineAddonsProduct = (
  brandId: number,
  productId: number,
  product: any
) =>
  axios.post(`/api/shopline/brands/${brandId}/addons/${productId}`, {
    ...product,
  });

export const fetchShoplineAddonsPreorder = (
  brandId: number,
  productId: number
) =>
  axios.get(
    `/api/shopline/brands/${brandId}/addons/${productId}/preorder_dates`
  );

export const updateShoplineAddonsPreorder = (
  brandId: number,
  productId: number,
  order_created_at: string,
  delivery_at: string
) =>
  axios.post(
    `/api/shopline/brands/${brandId}/addons/${productId}/preorder_dates`,
    {
      order_created_at,
      delivery_at,
    }
  );

export const deleteShoplineAddonsPreorder = (
  brandId: number,
  productId: number,
  preorderId: number
) =>
  axios.delete(
    `/api/shopline/brands/${brandId}/addons/${productId}/preorder_dates/${preorderId}`
  );
