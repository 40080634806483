import axios from 'axios';

const addAuthorizationHeader = (state, config) => {
  const userToken = state?.auth?.token;
  if (!userToken) {
    return config;
  }
  config.headers.Authorization = `Bearer ${userToken}`;

  return config;
};

export const addAxiosAuthInterceptor = (store) => {
  axios.interceptors.request.use((config) => {
    const state = store.getState(store);
    return addAuthorizationHeader(state, config);
  });
};
