import { GET_TINYMCE, PluginsActions } from './types';

export interface PluginsState {
  TinyMCE: {
    key: string;
  };
}

const initialState: PluginsState = {
  TinyMCE: {
    key: '',
  },
};

const customizerReducer = (
  state = initialState,
  action: PluginsActions
): PluginsState => {
  switch (action.type) {
    case GET_TINYMCE:
      return { ...state, TinyMCE: { key: action.payload.key } };
    default:
      return state;
  }
};

export default customizerReducer;
