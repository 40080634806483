import axios from 'axios';
import {
  BaseCategoryDetail,
  Category,
  CategoryDetail,
  CategoryProduct,
  CategorySort,
} from 'types';

export const fetchCategories = (brandId: number) =>
  axios.get<Category[]>(`/api/brands/${brandId}/categories`);

export const fetchCategory = (brandId: number, categoryId: number) =>
  axios.get<CategoryDetail>(`/api/brands/${brandId}/categories/${categoryId}`);

export const createCategory = (brandId: number, formData: BaseCategoryDetail) =>
  axios.post(`/api/brands/${brandId}/categories`, formData);

export const updateCategory = (
  brandId: number,
  categoryId: number,
  formData: BaseCategoryDetail
) => axios.post(`/api/brands/${brandId}/categories/${categoryId}`, formData);

export const deleteCategory = (brandId: number, categoryId: number) =>
  axios.delete(`/api/brands/${brandId}/categories/${categoryId}`);

export const updateCategoriesSort = (
  brandId: number,
  formData: CategorySort[]
) => axios.post(`/api/brands/${brandId}/categories/sorts`, formData);

export const fetchCategoryProducts = (brandId: number, categoryId: number) =>
  axios.get<CategoryProduct[]>(
    `/api/brands/${brandId}/categories/${categoryId}/products`
  );

export const updateCategoryProduct = (
  brandId: number,
  categoryId: number,
  formData: number[]
) =>
  axios.post(
    `/api/brands/${brandId}/categories/${categoryId}/products`,
    formData
  );
