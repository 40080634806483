import { BrandItem } from 'types';

export const CHANGE_BRAND = 'CHANGE_BRAND';

interface ChangeBrandAction {
  type: typeof CHANGE_BRAND;
  brandId: number;
  brands: BrandItem[];
}

export type SidebarActionTypes = ChangeBrandAction;
