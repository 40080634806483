import {
  fetchLineGiftBrandECSkuStatistics,
  fetchLineGiftBrandSalesAmount,
  fetchLineGiftBrandSalesOrders,
  fetchLineGiftBrandSkuStatistics,
  getStatistics,
} from 'api';

export const fetchLineGiftDailyStatistics = async (
  brandId: number,
  startDate: string,
  endDate: string
) => {
  const results = await Promise.all([
    fetchLineGiftBrandSalesAmount(brandId, startDate, endDate),
    fetchLineGiftBrandSalesOrders(brandId, startDate, endDate),
    fetchLineGiftBrandSkuStatistics(brandId, startDate, endDate),
  ]);

  return getStatistics(
    [results[0].data, results[1].data, results[2].data],
    startDate,
    endDate
  );
};

export const fetchLineGiftDailyECStatistics = async (
  brandId: number,
  startDate: string,
  endDate: string
) => {
  const results = await Promise.all([
    fetchLineGiftBrandSalesAmount(brandId, startDate, endDate),
    fetchLineGiftBrandSalesOrders(brandId, startDate, endDate),
    fetchLineGiftBrandECSkuStatistics(brandId, startDate, endDate),
  ]);

  return getStatistics(
    [results[0].data, results[1].data, results[2].data],
    startDate,
    endDate
  );
};
