import axios from 'axios';
import { Sku, SkuParams } from 'types';

export const fetchSkus = (brandId: number) =>
  axios.get<Sku[]>(`/api/brands/${brandId}/skus`);

export const fetchSku = (brandId: number, skuId: number) =>
  axios.get<Sku>(`/api/brands/${brandId}/skus/${skuId}`);

export const updateSku = (
  brandId: number,
  skuId: number,
  formData: SkuParams
) => axios.post(`/api/brands/${brandId}/skus/${skuId}`, formData);

export const createSku = (brandId: number, formData: SkuParams) =>
  axios.post(`/api/brands/${brandId}/skus`, formData);
