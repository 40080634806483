import axios from 'axios';

export const fetchShoplineAutoAllocationLists = (brandId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/auto-storage-categories`);

export const fetchShoplineAutoAllocationProportions = (
  brandId: number,
  categoryId: number
) =>
  axios.get(
    `/api/shopline/brands/${brandId}/auto-storage-categories/${categoryId}`
  );

export const fetchShoplineAutoAllocationAvailbleProducts = (brandId: number) =>
  axios.get(
    `/api/shopline/brands/${brandId}/auto-storage-categories/available-products`
  );

export const addShoplineAutoAllocationLists = (
  brandId: number,
  allocation: any
) =>
  axios.post(`/api/shopline/brands/${brandId}/auto-storage-categories`, {
    ...allocation,
  });

export const updateShoplineAutoAllocationLists = (
  brandId: number,
  categoryId: number,
  allocation: any
) =>
  axios.post(
    `/api/shopline/brands/${brandId}/auto-storage-categories/${categoryId}`,
    {
      ...allocation,
    }
  );
