import { LOGIN, LOGOUT, CHANGE_ROLE, AuthActionTypes } from './types';

const initialState = {
  role: '',
};

const auth = (state = initialState, action: AuthActionTypes) => {
  switch (action.type) {
    case CHANGE_ROLE: {
      return { ...state, role: action.role };
    }
    case LOGIN: {
      return { ...state, ...action.payload };
    }
    case LOGOUT: {
      return { role: '' };
    }
    default: {
      return state;
    }
  }
};

export default auth;
