import axios from 'axios';

import {
  Role,
  UserBrand,
  BrandItem,
  User,
  UserDetail,
  SidebarItemTypes,
} from '../types';

export const login = (values: any) =>
  axios.post('/api/authenticate/login/user', values);

interface AuthenticateResponse {
  user: {
    name: string;
    email: string;
    role: string;
    brand_id: number;
    avatar_url?: string;
  };
  brands: BrandItem[];
  global_menu: SidebarItemTypes[];
  brands_menu: SidebarItemTypes[];
}

export const authenticate = () =>
  axios.get<AuthenticateResponse>('/api/authenticate');

export const fetchUsers = () => axios.get<User[]>('/api/users');

export const fetchUser = (userId: string) =>
  axios.get<UserDetail>(`/api/users/${userId}`);

export const fetchRoles = () => axios.get<Role[]>('/api/roles');

export const fetchBrands = () => axios.get<UserBrand[]>('/api/brands/all');

export const updateUserBrands = (userId: string, brandIds: number[]) =>
  axios.patch<UserBrand[]>(`/api/users/${userId}/brands`, { brands: brandIds });

export const updateUserRole = (userId: string, roleId: number) =>
  axios.patch<Role>(`/api/users/${userId}/role`, { role_id: roleId });
