import axios from 'axios';

export const updateProductPayment = (
  brandId: number,
  productId: number,
  paymentIds: number[]
) => {
  return axios.post(
    `/api/brands/${brandId}/products/${productId}/payment_methods`,
    paymentIds
  );
};
