import React from 'react';

import classnames from 'classnames';
import * as Icon from 'react-feather';
import { Navbar, NavItem, NavLink } from 'reactstrap';
import { NavbarColor, NavbarType } from 'redux/customizer/types';

import NavbarUser from './NavbarUser';

interface IProps {
  navbarColor: NavbarColor;
  navbarType: NavbarType;
  sidebarVisibility: () => void;
}

const ThemeNavbar: React.FC<IProps> = (props) => {
  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark'];
  const navbarTypes = ['floating', 'static', 'sticky', 'hidden'];
  return (
    <>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
          {
            'navbar-light':
              props.navbarColor === 'default' ||
              !colorsArr.includes(props.navbarColor),
            'navbar-dark': colorsArr.includes(props.navbarColor),
            'bg-primary':
              props.navbarColor === 'primary' && props.navbarType !== 'static',
            'bg-danger':
              props.navbarColor === 'danger' && props.navbarType !== 'static',
            'bg-success':
              props.navbarColor === 'success' && props.navbarType !== 'static',
            'bg-info':
              props.navbarColor === 'info' && props.navbarType !== 'static',
            'bg-warning':
              props.navbarColor === 'warning' && props.navbarType !== 'static',
            'bg-dark':
              props.navbarColor === 'dark' && props.navbarType !== 'static',
            'd-none': props.navbarType === 'hidden',
            'floating-nav':
              props.navbarType === 'floating' ||
              !navbarTypes.includes(props.navbarType),
            'navbar-static-top': props.navbarType === 'static',
            'fixed-top': props.navbarType === 'sticky',
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <ul className="navbar-nav d-xl-none">
                    <NavItem className="mobile-menu mr-auto">
                      <NavLink
                        className="nav-menu-main menu-toggle hidden-xs is-active"
                        onClick={props.sidebarVisibility}
                      >
                        <Icon.Menu className="ficon" />
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
              </div>
              <NavbarUser />
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default ThemeNavbar;
