import { BrandItem } from 'types';

import { SidebarItemTypes } from '../../types';
import {
  AUTHENTICATE,
  AUTHENTICATE_ERROR,
  AuthActionTypes,
  LOGOUT,
} from '../auth/types';
import { CHANGE_BRAND, SidebarActionTypes } from './types';

interface SidebarState {
  authorized: boolean;
  brandId: number;
  brandCode: string;
  brands: BrandItem[];
  globalMenu: SidebarItemTypes[];
  brandsMenu: SidebarItemTypes[];
}

const initialState: SidebarState = {
  authorized: false,
  brandId: -1,
  brandCode: '',
  brands: [],
  globalMenu: [],
  brandsMenu: [],
};

const getBrandCode = (brandId: number, brands: BrandItem[]) => {
  const currentBrand = brands.find((brand) => brand.id === brandId);
  return currentBrand?.code;
};

const sidebar = (
  state = initialState,
  action: AuthActionTypes | SidebarActionTypes
): SidebarState => {
  switch (action.type) {
    case AUTHENTICATE: {
      const { brands, globalMenu, brandsMenu } = action.payload;
      let brandId = state.brandId;
      if (brandId === -1 && brands.length > 0) {
        brandId = brands[0].id;
      }

      let brandCode = state.brandCode;
      if (brandCode === '' && brands.length > 0) {
        brandCode = brands[0].code;
      }

      return {
        ...state,
        authorized: true,
        brandId,
        brandCode,
        brands: brands,
        globalMenu,
        brandsMenu,
      };
    }
    case CHANGE_BRAND: {
      return {
        ...state,
        brandId: action.brandId,
        brandCode: getBrandCode(action.brandId, action.brands) ?? '',
      };
    }
    case AUTHENTICATE_ERROR: {
      return initialState;
    }
    case LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default sidebar;
