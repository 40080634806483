import axios from 'axios';
import { Booster, BoosterProduct, CreateBooster, UpdatingBooster } from 'types';

export const fetchBoosters = (brandId: number) =>
  axios.get<Booster[]>(`/api/brands/${brandId}/boosters`);

export const fetchBooster = (brandId: number, boosterId: number) =>
  axios.get<Booster>(`/api/brands/${brandId}/boosters/${boosterId}`);

export const createBooster = (brandId: number, booster: CreateBooster) =>
  axios.post(`/api/brands/${brandId}/boosters`, booster);

export const updateBooster = (
  brandId: number,
  boosterId: number,
  formData: UpdatingBooster
) => axios.post(`/api/brands/${brandId}/boosters/${boosterId}`, formData);

export const fetchBoosterProducts = (brandId: number, boosterId: number) =>
  axios.get<BoosterProduct[]>(
    `/api/brands/${brandId}/boosters/${boosterId}/products`
  );

export const updateBoosterProducts = (
  brandId: number,
  boosterId: number,
  productIds: number[]
) =>
  axios.post(
    `/api/brands/${brandId}/boosters/${boosterId}/products`,
    productIds
  );

export const deleteBooster = (brandId: number, boosterId: number) =>
  axios.delete(`/api/brands/${brandId}/boosters/${boosterId}`);
