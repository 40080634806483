import axios from 'axios';
import { RepeatPurchase, TopCustomer } from 'types';

export const fetchRepeatPurchaseRate = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<RepeatPurchase>(
    `/api/brands/${brandId}/statistics/repeat-purchase-rate`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchTopCustomers = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TopCustomer[]>(`/api/brands/${brandId}/statistics/top-customers`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
