import axios from 'axios';
import { ConditionCampaign, NewConditionCampaign } from 'types';

export const fetchConditionCampaigns = (brandId: number) =>
  axios.get<ConditionCampaign[]>(`/api/brands/${brandId}/condition_campaigns`);

export const fetchConditionCampaign = (
  brandId: number,
  conditionCampaignId: number
) =>
  axios.get<ConditionCampaign>(
    `/api/brands/${brandId}/condition_campaigns/${conditionCampaignId}`
  );

export const createConditionCampaign = (
  brandId: number,
  formData: NewConditionCampaign
) => axios.post(`/api/brands/${brandId}/condition_campaigns`, formData);

export const updateConditionCampaign = (
  brandId: number,
  conditionCampaignId: number,
  formData: NewConditionCampaign
) =>
  axios.post(
    `/api/brands/${brandId}/condition_campaigns/${conditionCampaignId}`,
    formData
  );

export const deleteConditionCampaign = (
  brandId: number,
  conditionCampaignId: number
) =>
  axios.delete(
    `/api/brands/${brandId}/condition_campaigns/${conditionCampaignId}`
  );
