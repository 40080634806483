import axios from 'axios';
import {
  ProductCyclePurchaseRate,
  ProductCyclePurchaseRateParams,
} from 'types';

export const fetchProductCyclePurchaseRate = (
  brandId: number,
  params: ProductCyclePurchaseRateParams
) =>
  axios.get<ProductCyclePurchaseRate[]>(
    `/api/brands/${brandId}/statistics/product-cycle-purchase-rates`,
    {
      params,
    }
  );
