import React, { useEffect } from 'react';

import Combokeys from 'combokeys';
import { connect } from 'react-redux';

import { changeMode } from '../../redux/actions';
import { ThemeStyle } from '../../redux/customizer/types';
import { RootState } from '../../redux/reducers';

interface IProps {
  theme: ThemeStyle;
  changeMode: typeof changeMode;
}

const ComboKeys: React.FC<IProps> = ({ theme, changeMode }) => {
  useEffect(() => {
    const combokeys = new Combokeys(document.documentElement);
    combokeys.bind(
      [
        'option+shift+l',
        'alt+shift+l',
        'up up down down left right left right a b',
      ],
      () => {
        const mode = theme === 'light' ? 'dark' : 'light';
        changeMode(mode);
      }
    );

    return () => {
      combokeys.detach();
    };
  }, [theme, changeMode]);
  return <></>;
};

const mapStateToProps = ({ customizer: { theme } }: RootState) => ({ theme });

export default connect(mapStateToProps, { changeMode })(ComboKeys);
