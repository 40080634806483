import axios from 'axios';

export const deleteProductImage = (
  brandId: number,
  productId: number,
  imageId: number
) => {
  return axios.delete(
    `/api/brands/${brandId}/products/${productId}/images/${imageId}`
  );
};
