import axios from 'axios';
import { ProductSku, ProductSkuParams } from 'types';

export const fetchProductSku = (brandId: number, productId: number) => {
  return axios.get<ProductSku[]>(
    `/api/brands/${brandId}/products/${productId}/skus`
  );
};

export const updateProductSku = (
  brandId: number,
  productId: number,
  params: ProductSkuParams[]
) => {
  return axios.post(
    `/api/brands/${brandId}/products/${productId}/skus`,
    params
  );
};
