import React from 'react';

import MonoCollapsed from 'components/collapsed/MonoCollapsed';
import { Media } from 'reactstrap';
import { BrandItem } from 'types';

interface IProps {
  brandId: number;
  brands: BrandItem[];
  onBrandChange: (brandId: number) => void;
}

class SideMenuBrandHeader extends React.Component<IProps> {
  renderHeader() {
    const { brandId, brands } = this.props;
    const brand = brands.find((brand) => brand.id === brandId);

    return brand ? (
      <Media className="p-0">
        <Media left href="#">
          <Media
            object
            src={brand.logo_url}
            height="auto"
            width="42"
            alt="Generic placeholder image"
          />
        </Media>
        <Media body className="font-small-1">
          <Media heading className="h5">
            {brand.name}
          </Media>
          台灣
        </Media>
      </Media>
    ) : null;
  }

  renderBrands() {
    const { brands, onBrandChange } = this.props;
    return brands.map((brand) => (
      <Media
        key={brand.id}
        className="p-0 cursor-pointer"
        onClick={() => onBrandChange(brand.id)}
      >
        <Media left href="#">
          <Media
            object
            src={brand.logo_url}
            height="auto"
            width="42"
            alt="Generic placeholder image"
          />
        </Media>
        <Media body className="font-small-1">
          <Media heading className="h6">
            {brand.name}
          </Media>
          台灣
        </Media>
      </Media>
    ));
  }

  render() {
    return (
      <div className="media-list mt-1">
        <hr />
        <MonoCollapsed
          header={this.renderHeader()}
          body={this.renderBrands()}
        />
        <hr />
      </div>
    );
  }
}

export default SideMenuBrandHeader;
