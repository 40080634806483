import {
  CHANGE_FOOTER_TYPE,
  CHANGE_MENU_COLOR,
  CHANGE_MODE,
  CHANGE_NAVBAR_COLOR,
  CHANGE_NAVBAR_TYPE,
  COLLAPSE_SIDEBAR,
  HIDE_SCROLL_TO_TOP,
} from './types';
import {
  CustomizerActionTypes,
  FooterType,
  MenuTheme,
  NavbarColor,
  NavbarType,
  ThemeStyle,
} from './types';

export const changeMode = (mode: ThemeStyle): CustomizerActionTypes => {
  return { type: CHANGE_MODE, mode };
};

export const collapseSidebar = (value: boolean): CustomizerActionTypes => {
  return { type: COLLAPSE_SIDEBAR, value };
};

export const changeNavbarColor = (
  color: NavbarColor
): CustomizerActionTypes => {
  return { type: CHANGE_NAVBAR_COLOR, color };
};

export const changeNavbarType = (style: NavbarType): CustomizerActionTypes => {
  return { type: CHANGE_NAVBAR_TYPE, style };
};

export const changeFooterType = (style: FooterType): CustomizerActionTypes => {
  return { type: CHANGE_FOOTER_TYPE, style };
};

export const changeMenuColor = (style: MenuTheme): CustomizerActionTypes => {
  return { type: CHANGE_MENU_COLOR, style };
};

export const hideScrollToTop = (value: boolean): CustomizerActionTypes => {
  return { type: HIDE_SCROLL_TO_TOP, value };
};
