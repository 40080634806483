import { BrandItem } from 'types';

import { CHANGE_BRAND, SidebarActionTypes } from './types';

export const changeBrand = (
  brandId: number,
  brands: BrandItem[]
): SidebarActionTypes => {
  return { type: CHANGE_BRAND, brandId, brands };
};
