import axios from 'axios';
import { ProductRepeatPurchase } from 'types';

export const fetchProductRepeatPurchase = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<ProductRepeatPurchase[]>(
    `/api/brands/${brandId}/statistics/product-repeat-purchase`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );
