import axios from 'axios';
import { getYear } from 'date-fns';
import {
  StatisticsMetric,
  TotalStatisticsMetric,
  SalesGoal,
  TopProduct,
} from 'types';
import { getRealMonth } from 'utility/date';

export const fetchShoplineBrandRevenueStatistics = (
  brandId: number,
  currentMonth: Date
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/shopline/brands/${brandId}/statistics/revenue`,
    {
      params: {
        year: getYear(currentMonth),
        month: getRealMonth(currentMonth),
        timezone_offset: 8 * 3600,
      },
    }
  );

export const fetchShoplineBrandSalesGoal = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<SalesGoal>(
    `/api/shopline/brands/${brandId}/statistics/sales-goal`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchShoplineBrandTopProducts = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TopProduct[]>(
    `/api/shopline/brands/${brandId}/statistics/top-products`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchShoplineBrandSalesAmount = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(
    `/api/shopline/brands/${brandId}/statistics/amount`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchShoplineBrandSalesOrders = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(
    `/api/shopline/brands/${brandId}/statistics/orders`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchShoplineBrandSkuStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(
    `/api/shopline/brands/${brandId}/statistics/skus`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchShoplineBrandECSkuStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(
    `/api/shopline/brands/${brandId}/statistics/ec_skus`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchShoplineBrandOrderCountStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/shopline/brands/${brandId}/statistics/order-count`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchShoplineBrandOrderHourCountStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/shopline/brands/${brandId}/statistics/order-hour-count`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );
