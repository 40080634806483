import axios from 'axios';
import { ProductInventoryParams } from 'types';

export const updateProductInventory = (
  brandId: number,
  productId: number,
  formData: ProductInventoryParams
) => {
  return axios.post(
    `/api/brands/${brandId}/products/${productId}/inventory`,
    formData
  );
};

export const releaseProductInventory = (brandId: number, productId: number) => {
  return axios.post(
    `/api/brands/${brandId}/products/${productId}/inventory/release`
  );
};
