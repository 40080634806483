import axios from 'axios';

import { WarehouseParams, Warehouse } from '../types';

export const fetchWarehouses = (brandId: number) =>
  axios.get<Warehouse[]>(`/api/brands/${brandId}/warehouses`);

export const updateWarehouses = (
  brandId: number,
  warehouseId: number,
  formData: WarehouseParams
) => axios.post(`/api/brands/${brandId}/warehouses/${warehouseId}`, formData);

export const createWarehouse = (brandId: number, formData: WarehouseParams) =>
  axios.post<WarehouseParams>(`/api/brands/${brandId}/warehouses`, formData);
