import React from 'react';

import * as Icon from 'react-feather';
import { Props } from 'react-feather';

interface IProps extends Props {
  icon?: string;
}

const FeatherIcon: React.FC<IProps> = ({ icon, ...rest }) => {
  // @ts-ignore
  const Component = Icon[icon] ?? Icon.HelpCircle;
  return <Component {...rest} />;
};

export default FeatherIcon;
