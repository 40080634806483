import React from 'react';

interface IProps {
  title: string;
}

const SideMenuGroupHeader: React.FC<IProps> = ({ title }) => {
  return (
    <li className="navigation-header" key={`group-header-${title}`}>
      <span>{title}</span>
    </li>
  );
};

export default SideMenuGroupHeader;
