import axios from 'axios';
import { ProductInformation } from 'types';

export const updateProductInformation = (
  brandId: number,
  productId: number,
  formData: ProductInformation
) => {
  return axios.post(
    `/api/brands/${brandId}/products/${productId}/information`,
    formData
  );
};
