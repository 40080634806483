import React, { Suspense, lazy } from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import Spinner from './components/@vuexy/spinner/Fallback-spinner';
import { store, persistor } from './redux/store';
import * as serviceWorker from './serviceWorker';
import { Layout } from './utility/context/Layout';
import './index.scss';
import './api/@fake';

const LazyApp = lazy(() => import('./App'));

// configureDatabase()
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Spinner />} persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <LazyApp />
        </Layout>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
