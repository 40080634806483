import axios from 'axios';
import {
  BaseFaq,
  BaseFaqSub,
  BaseFaqSubItem,
  Faq,
  FaqSub,
  FaqSubItem,
} from 'types';

export const fetchFaqs = (brandId: number) =>
  axios.get<Faq[]>(`/api/brands/${brandId}/faqs`);

export const fetchFaq = (brandId: number, faqId: number) =>
  axios.get<Faq>(`/api/brands/${brandId}/faqs/${faqId}`);

export const createFaq = (brandId: number, formData: BaseFaq) =>
  axios.post(`/api/brands/${brandId}/faqs`, formData);

export const updateFaq = (brandId: number, faqId: number, formData: BaseFaq) =>
  axios.post(`/api/brands/${brandId}/faqs/${faqId}`, formData);

export const deleteFaq = (brandId: number, faqId: number) =>
  axios.delete(`/api/brands/${brandId}/faqs/${faqId}`);

export const fetchFaqSubs = (brandId: number, faqId: number) =>
  axios.get<FaqSub[]>(`/api/brands/${brandId}/faqs/${faqId}/faq_subs`);

export const fetchFaqSub = (brandId: number, faqId: number, faqSubId: number) =>
  axios.get<FaqSub>(
    `/api/brands/${brandId}/faqs/${faqId}/faq_subs/${faqSubId}`
  );

export const createFaqSub = (
  brandId: number,
  faqId: number,
  formData: BaseFaqSub
) => axios.post(`/api/brands/${brandId}/faqs/${faqId}/faq_subs`, formData);

export const updateFaqSub = (
  brandId: number,
  faqId: number,
  faqSubId: number,
  formData: BaseFaqSub
) =>
  axios.post(
    `/api/brands/${brandId}/faqs/${faqId}/faq_subs/${faqSubId}`,
    formData
  );

export const deleteFaqSub = (
  brandId: number,
  faqId: number,
  faqSubId: number
) => axios.delete(`/api/brands/${brandId}/faqs/${faqId}/faq_subs/${faqSubId}`);

export const fetchFaqSubItems = (
  brandId: number,
  faqId: number,
  faqSubId: number
) =>
  axios.get<FaqSubItem[]>(
    `/api/brands/${brandId}/faqs/${faqId}/faq_subs/${faqSubId}/faq_sub_items`
  );

export const fetchFaqSubItem = (
  brandId: number,
  faqId: number,
  faqSubId: number,
  faqSubItemId: number
) =>
  axios.get<FaqSubItem>(
    `/api/brands/${brandId}/faqs/${faqId}/faq_subs/${faqSubId}/faq_sub_items/${faqSubItemId}`
  );

export const createFaqSubItem = (
  brandId: number,
  faqId: number,
  faqSubId: number,
  formData: BaseFaqSubItem
) =>
  axios.post(
    `/api/brands/${brandId}/faqs/${faqId}/faq_subs/${faqSubId}/faq_sub_items`,
    formData
  );

export const updateFaqSubItem = (
  brandId: number,
  faqId: number,
  faqSubId: number,
  faqSubItemId: number,
  formData: BaseFaqSubItem
) =>
  axios.post(
    `/api/brands/${brandId}/faqs/${faqId}/faq_subs/${faqSubId}/faq_sub_items/${faqSubItemId}`,
    formData
  );

export const deleteFaqSubItem = (
  brandId: number,
  faqId: number,
  faqSubId: number,
  faqSubItemId: number
) =>
  axios.delete(
    `/api/brands/${brandId}/faqs/${faqId}/faq_subs/${faqSubId}/faq_sub_items/${faqSubItemId}`
  );
