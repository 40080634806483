import axios from 'axios';

import { AddonProduct, UpdateAddonProduct } from '../types';

export const fetchAddonProducts = (brandId: number) =>
  axios.get<AddonProduct[]>(`/api/brands/${brandId}/addon_products`);

export const fetchAddonProductDetail = (brandId: number, addonId: number) =>
  axios.get<AddonProduct>(`/api/brands/${brandId}/addon_products/${addonId}`);

export const createAddonProduct = (
  brandId: number,
  formData: UpdateAddonProduct
) => axios.post(`/api/brands/${brandId}/addon_products`, formData);

export const updateAddonProductDetail = (
  brandId: number,
  addonId: number,
  formData: UpdateAddonProduct
) => axios.post(`/api/brands/${brandId}/addon_products/${addonId}`, formData);
