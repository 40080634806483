import axios from 'axios';

import {
  AddonCampaign,
  CreateAddonCampaign,
  AddonCampaignDetail,
  UpdateAddonCampaignParams,
} from '../types';

export const fetchAddonCampaigns = (brandId: number) =>
  axios.get<AddonCampaign[]>(`/api/brands/${brandId}/addon_campaigns`);

export const fetchAddonCampaignDetail = (brandId: number, campaignId: number) =>
  axios.get<AddonCampaignDetail>(
    `/api/brands/${brandId}/addon_campaigns/${campaignId}`
  );

export const createAddonCampaign = (
  brandId: number,
  formData: CreateAddonCampaign
) => axios.post(`/api/brands/${brandId}/addon_campaigns`, formData);

export const updateAddonCampaignDetail = (
  brandId: number,
  campaignId: number,
  formData: UpdateAddonCampaignParams
) =>
  axios.post(`/api/brands/${brandId}/addon_campaigns/${campaignId}`, formData);
