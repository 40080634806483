import React from 'react';

import classnames from 'classnames';
import { ChevronDown } from 'react-feather';
import { Collapse } from 'reactstrap';

interface IProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
}

interface IState {
  status: string;
  collapsed: boolean;
}

class MonoCollapsed extends React.Component<IProps, IState> {
  state = {
    status: 'Closed',
    collapsed: true,
  };

  toggleCollapse = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  onEntered = () => {
    this.setState({ status: 'Opened' });
  };

  onEntering = () => {
    this.setState({ status: 'Opening...' });
  };

  onExited = () => {
    this.setState({ status: 'Closed' });
  };

  onExiting = () => {
    this.setState({ status: 'Closing...' });
  };

  render() {
    const { header, body } = this.props;
    const { status, collapsed } = this.state;
    return (
      <div className="media-list mt-1">
        <div className="vx-collapse collapse-bordered">
          <div
            className={classnames({
              'collapse-collapsed': status === 'Closed',
              'collapse-shown': status === 'Opened',
              closing: status === 'Closing...',
              opening: status === 'Opening...',
            })}
          >
            <div
              className="px-2 d-flex flex-wrap justify-content-between align-items-center cursor-pointer"
              onClick={() => this.toggleCollapse()}
            >
              <div className="collapse-title collapsed">{header}</div>
              <ChevronDown size={15} className="collapse-icon" />
            </div>
            <Collapse
              isOpen={!collapsed}
              onEntering={() => this.onEntering()}
              onEntered={() => this.onEntered()}
              onExiting={() => this.onExiting()}
              onExited={() => this.onExited()}
            >
              <div className="px-2 pt-1">{body}</div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

export default MonoCollapsed;
