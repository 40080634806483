import axios from 'axios';
import { BaseBanner, Banner } from 'types';

export const fetchBanners = (brandId: number) =>
  axios.get<Banner[]>(`/api/brands/${brandId}/banners`);

export const createBanner = (brandId: number, formData: BaseBanner) =>
  axios.post(`/api/brands/${brandId}/banners`, formData);

export const updateBanner = (
  brandId: number,
  bannerId: number,
  formData: BaseBanner
) => axios.post(`/api/brands/${brandId}/banners/${bannerId}`, formData);

export const deleteBanner = (brandId: number, bannerId: number) =>
  axios.delete(`/api/brands/${brandId}/banners/${bannerId}`);
