import axios from 'axios';
import { LibraryImage } from 'types';

export const fetchLibraryImages = (brandId: number) =>
  axios.get<LibraryImage[]>(`/api/brands/${brandId}/images`);

export const deleteLibraryImage = (brandId: number, imageId: number) =>
  axios.delete<LibraryImage[]>(`/api/brands/${brandId}/images/${imageId}`);

export const uploadLibraryImage = (
  brandId: number,
  data: FormData,
  type: string,
  extension: string
) => {
  return axios({
    method: 'post',
    url: `/api/brands/${brandId}/images`,
    data,
    headers: {
      'Mime-Type': type,
      Accept: 'application/json',
      Extension: extension,
    },
  });
};

export const updateLibraryImage = (
  brandId: number,
  imageId: number,
  data: LibraryImage
) => axios.post(`/api/brands/${brandId}/images/${imageId}`, data);
