import axios from 'axios';
import { Customer, CustomerDetail, CustomerShopline } from 'types';

export const fetchCustomerSearch = (
  brandId: number,
  params: { [k: string]: string }
) =>
  axios.get<Customer[]>(`/api/brands/${brandId}/customers/search`, {
    params,
  });

export const fetchCustomers = (brandId: number) =>
  axios.get<Customer[]>(`/api/brands/${brandId}/customers`);

export const fetchCustomer = (brandId: number, customerId: number) =>
  axios.get<CustomerDetail>(`/api/brands/${brandId}/customers/${customerId}`);

export const archiveCustomer = (brandId: number, customerId: number) =>
  axios.post(`/api/brands/${brandId}/customers/${customerId}/archive`);

export const bindCustomerCoupons = (
  brandId: number,
  customerId: number,
  codes: string[]
) =>
  axios.post(
    `/api/brands/${brandId}/customers/${customerId}/bind_coupons`,
    codes
  );

export const unbindCustomerCoupons = (
  brandId: number,
  customerId: number,
  code: string
) =>
  axios.post(
    `/api/brands/${brandId}/customers/${customerId}/unbind_coupon`,
    JSON.stringify(code),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

export const fetchCustomersShopline = (brandId: number) =>
  axios.get<CustomerShopline[]>(`/api/shopline/brands/${brandId}/customers`);
