import axios from 'axios';

export const fetchAutoAllocationLists = (brandId: number) =>
  axios.get(`/api/brands/${brandId}/auto-storage-categories`);

export const fetchAutoAllocationProportions = (
  brandId: number,
  categoryId: number
) => axios.get(`/api/brands/${brandId}/auto-storage-categories/${categoryId}`);

export const fetchAutoAllocationAvailbleProducts = (brandId: number) =>
  axios.get(
    `/api/brands/${brandId}/auto-storage-categories/available-products`
  );

export const addAutoAllocationLists = (brandId: number, allocation: any) =>
  axios.post(`/api/brands/${brandId}/auto-storage-categories`, {
    ...allocation,
  });

export const updateAutoAllocationLists = (
  brandId: number,
  categoryId: number,
  allocation: any
) =>
  axios.post(`/api/brands/${brandId}/auto-storage-categories/${categoryId}`, {
    ...allocation,
  });
