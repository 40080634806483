import axios from 'axios';

export const fetchShoplineGiftsProducts = (brandId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/gifts`);

export const fetchShoplineGiftsProduct = (brandId: number, productId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/gifts/${productId}`);

export const updateShoplineGiftsProduct = (
  brandId: number,
  productId: number,
  product: any
) =>
  axios.post(`/api/shopline/brands/${brandId}/gifts/${productId}`, {
    ...product,
  });

export const fetchShoplineGiftsPreorder = (
  brandId: number,
  productId: number
) =>
  axios.get(
    `/api/shopline/brands/${brandId}/gifts/${productId}/preorder_dates`
  );

export const updateShoplineGiftsPreorder = (
  brandId: number,
  productId: number,
  order_created_at: string,
  delivery_at: string
) =>
  axios.post(
    `/api/shopline/brands/${brandId}/gifts/${productId}/preorder_dates`,
    {
      order_created_at,
      delivery_at,
    }
  );

export const deleteShoplineGiftsPreorder = (
  brandId: number,
  productId: number,
  preorderId: number
) =>
  axios.delete(
    `/api/shopline/brands/${brandId}/gifts/${productId}/preorder_dates/${preorderId}`
  );
