export const CHANGE_MODE = 'CHANGE_MODE';
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR';
export const CHANGE_NAVBAR_COLOR = 'CHANGE_NAVBAR_COLOR';
export const CHANGE_NAVBAR_TYPE = 'CHANGE_NAVBAR_TYPE';
export const CHANGE_FOOTER_TYPE = 'CHANGE_FOOTER_TYPE';
export const CHANGE_MENU_COLOR = 'CHANGE_MENU_COLOR';
export const HIDE_SCROLL_TO_TOP = 'HIDE_SCROLL_TO_TOP';

export type ThemeStyle = 'light' | 'dark' | 'semi-dark';
export type NavbarColor =
  | 'default'
  | 'primary'
  | 'success'
  | 'danger'
  | 'info'
  | 'warning'
  | 'dark';

export type NavbarType = 'floating' | 'static' | 'sticky' | 'hidden';
export type FooterType = 'static' | 'sticky' | 'hidden';
export type MenuTheme =
  | 'primary'
  | 'success'
  | 'danger'
  | 'info'
  | 'warning'
  | 'dark';
export type Direction = 'ltr' | 'rtl';

interface ChangeModeAction {
  type: typeof CHANGE_MODE;
  mode: ThemeStyle;
}

interface CollapseSidebarAction {
  type: typeof COLLAPSE_SIDEBAR;
  value: boolean;
}

interface ChangeNavbarColorAction {
  type: typeof CHANGE_NAVBAR_COLOR;
  color: NavbarColor;
}

interface ChangeNavbarTypeAction {
  type: typeof CHANGE_NAVBAR_TYPE;
  style: NavbarType;
}

interface ChangeFooterTypeAction {
  type: typeof CHANGE_FOOTER_TYPE;
  style: FooterType;
}

interface ChangeMenuColorAction {
  type: typeof CHANGE_MENU_COLOR;
  style: MenuTheme;
}

interface HideScrollToTop {
  type: typeof HIDE_SCROLL_TO_TOP;
  value: boolean;
}

export type CustomizerActionTypes =
  | ChangeModeAction
  | CollapseSidebarAction
  | ChangeNavbarColorAction
  | ChangeNavbarTypeAction
  | ChangeFooterTypeAction
  | ChangeMenuColorAction
  | HideScrollToTop;
