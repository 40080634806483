import {
  fetchBrandSalesAmount,
  fetchBrandSalesOrders,
  fetchBrandSkuStatistics,
  fetchBrandECSkuStatistics,
  fetchBrandEmailCounts,
  fetchBrandEmailListCounts,
} from 'api';
import { eachDayOfInterval, formatISO, parseISO } from 'date-fns';
import _ from 'lodash';
import { StatisticsMetric } from 'types';

export const getStatistics = async (
  statisticsItems: StatisticsMetric[],
  startDate: string,
  endDate: string
) => {
  const statisticsData: StatisticsMetric = statisticsItems[2];
  const parsedStatisticsData = statisticsData.metrics.filter((items) => {
    return items.data.some((item) => item !== 0) && items;
  });

  const parsedResult = [
    statisticsItems[0],
    statisticsItems[1],
    { labels: statisticsItems[1].labels, metrics: parsedStatisticsData },
  ];

  const metrics: { name: string; data: number[] }[] = _.flatten(
    parsedResult.map((res) => res.metrics)
  );
  const headers: string[] = ['日期', ...metrics.map((metric) => metric.name)];
  const dates = eachDayOfInterval({
    start: parseISO(startDate),
    end: parseISO(endDate),
  }).map((date) => formatISO(date, { representation: 'date' }));

  const rows: (string | number | undefined)[][] = _.zip(
    dates,
    ...metrics.map((metric: any) => metric.data)
  );
  let sumRowsData: { name: string; value: number }[] = [];

  const sumHeader = headers.slice(1);
  const sumRows: number[] = [];

  for (let i = 1; headers.length > i; i++) {
    var newArr = [];
    for (let j = 0; rows.length > j; j++) {
      newArr.push(rows[j][i]);
    }
    // @ts-ignore
    const sum: number = newArr.reduce((sum, current) => sum + current, 0);
    sumRows.push(sum);
  }

  sumRowsData = sumHeader.map((item, index) => ({
    name: item,
    value: sumRows[index],
  }));

  return { headers, rows, sumRowsData };
};

export const getStatistics_1 = async (
  statisticsItems: StatisticsMetric[],
  startDate: string,
  endDate: string
) => {
  const statisticsData: StatisticsMetric = statisticsItems[1];
  const parsedStatisticsData = statisticsData.metrics.filter((items) => {
    return items.data.some((item) => item !== 0) && items;
  });

  const parsedResult = [
    statisticsItems[0],
    { labels: statisticsItems[0].labels, metrics: parsedStatisticsData },
  ];

  const metrics: { name: string; data: number[] }[] = _.flatten(
    parsedResult.map((res) => res.metrics)
  );
  const headers: string[] = ['日期', ...metrics.map((metric) => metric.name)];
  const dates = eachDayOfInterval({
    start: parseISO(startDate),
    end: parseISO(endDate),
  }).map((date) => formatISO(date, { representation: 'date' }));

  const rows: (string | number | undefined)[][] = _.zip(
    dates,
    ...metrics.map((metric: any) => metric.data)
  );
  let sumRowsData: { name: string; value: number }[] = [];

  const sumHeader = headers.slice(1);
  const sumRows: number[] = [];

  for (let i = 1; headers.length > i; i++) {
    var newArr = [];
    for (let j = 0; rows.length > j; j++) {
      newArr.push(rows[j][i]);
    }
    // @ts-ignore
    const sum: number = newArr.reduce((sum, current) => sum + current, 0);
    sumRows.push(sum);
  }

  sumRowsData = sumHeader.map((item, index) => ({
    name: item,
    value: sumRows[index],
  }));

  return { headers, rows, sumRowsData };
};

export const fetchDailyStatistics = async (
  brandId: number,
  startDate: string,
  endDate: string
) => {
  const results = await Promise.all([
    fetchBrandSalesAmount(brandId, startDate, endDate),
    fetchBrandSalesOrders(brandId, startDate, endDate),
    fetchBrandSkuStatistics(brandId, startDate, endDate),
  ]);

  return getStatistics(
    [results[0].data, results[1].data, results[2].data],
    startDate,
    endDate
  );
};

export const fetchDailyECStatistics = async (
  brandId: number,
  startDate: string,
  endDate: string
) => {
  const results = await Promise.all([
    fetchBrandSalesAmount(brandId, startDate, endDate),
    fetchBrandSalesOrders(brandId, startDate, endDate),
    fetchBrandECSkuStatistics(brandId, startDate, endDate),
  ]);

  return getStatistics(
    [results[0].data, results[1].data, results[2].data],
    startDate,
    endDate
  );
};

export const fetchDailyEmailStatistics = async (
  brandId: number,
  startDate: string,
  endDate: string
) => {
  const results = await Promise.all([
    fetchBrandEmailCounts(brandId, startDate, endDate),
    fetchBrandEmailListCounts(brandId, startDate, endDate),
  ]);

  return getStatistics_1(
    [results[0].data, results[1].data],
    startDate,
    endDate
  );
};
