import * as api from 'api';
import { ThunkDispatch } from 'redux-thunk';

import { GET_TINYMCE, PluginsActions } from './types';

export const getTinyMCE = () => {
  return async (dispatch: ThunkDispatch<null, undefined, PluginsActions>) => {
    const res = await api.fetchThirdPartyKeys('TinyMCE');
    const { value } = res.data;
    dispatch({ type: GET_TINYMCE, payload: { key: value } });
  };
};
