import * as api from 'api';
import { ThunkDispatch } from 'redux-thunk';

import { history } from '../../history';
import {
  LOGIN,
  LOGOUT,
  AUTHENTICATE,
  AUTHENTICATE_ERROR,
  CHANGE_ROLE,
  AuthActionTypes,
} from './types';

export const changeRole = (role: string) => {
  return { type: CHANGE_ROLE, role };
};

export const login = (values: any) => {
  return (dispatch: ThunkDispatch<null, undefined, AuthActionTypes>) => {
    return api.login(values).then((res) => {
      const { token, user } = res.data;
      dispatch({ type: LOGIN, payload: { ...user, token } });
      history.push('/');
    });
  };
};

export const logout = () => {
  return (dispatch: ThunkDispatch<null, undefined, AuthActionTypes>) => {
    dispatch({ type: LOGOUT, payload: {} });
    history.push('/');
  };
};

export const authenticate = () => {
  return (dispatch: ThunkDispatch<null, undefined, AuthActionTypes>) => {
    return api
      .authenticate()
      .then((res) => {
        const { user, brands, global_menu, brands_menu } = res.data;
        dispatch({
          type: AUTHENTICATE,
          payload: {
            user,
            brands,
            globalMenu: global_menu,
            brandsMenu: brands_menu,
          },
        });
      })
      .catch((e) => {
        // HTTP 401 Unauthorized
        if (e?.response?.status === 401) {
          return dispatch(logout());
        }

        // HTTP 403 Forbidden
        if (e?.response?.status === 403) {
          return dispatch(logout());
        }

        dispatch({ type: AUTHENTICATE_ERROR });
      });
  };
};
