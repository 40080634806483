import { createMigrate, MigrationManifest } from 'redux-persist';

const migrations: MigrationManifest = {
  '0': (state) => {
    if (state) {
      return { _persist: state._persist };
    }
  },
};

export default createMigrate(migrations, { debug: false });
