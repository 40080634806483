import axios from 'axios';
import { ProductImage } from 'types';

export const fetchProduct = (brandId: number, productId: number) =>
  axios.get(`/api/brands/${brandId}/products/${productId}`);

export const updateProduct = (
  brandId: number,
  productId: number,
  product: any
) =>
  axios.post(`/api/brands/${brandId}/products/${productId}`, {
    ...product,
  });

export const fetchPreorder = (brandId: number, productId: number) =>
  axios.get(`/api/brands/${brandId}/products/${productId}/preorder_dates`);

export const updatePreorder = (
  brandId: number,
  productId: number,
  order_created_at: string,
  delivery_at: string
) =>
  axios.post(`/api/brands/${brandId}/products/${productId}/preorder_dates`, {
    order_created_at,
    delivery_at,
  });

export const deletePreorder = (
  brandId: number,
  productId: number,
  preorderId: number
) =>
  axios.delete(
    `/api/brands/${brandId}/products/${productId}/preorder_dates/${preorderId}`
  );

export const fetchFirstImage = (brandId: number, productId: number) =>
  axios.get<ProductImage>(
    `/api/brands/${brandId}/products/${productId}/images/first`
  );
