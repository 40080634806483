import axios from 'axios';
import {
  LogisticsMethod,
  LogisticsCountry,
  LogisticsMethodDetail,
  CreateLogisticsMethod,
  UpdateLogisticsMethod,
  Delivery,
} from 'types';

export const fetchLogisticsMethods = (brandId: number) =>
  axios.get<LogisticsMethod[]>(`/api/brands/${brandId}/logistics_methods`);

export const createLogisticsMethod = (
  brandId: number,
  data: CreateLogisticsMethod
) => axios.post(`/api/brands/${brandId}/logistics_methods`, data);

export const fetchLogisticsMethod = (brandId: number, logisticsId: number) =>
  axios.get<LogisticsMethodDetail>(
    `/api/brands/${brandId}/logistics_methods/${logisticsId}`
  );

export const updateLogisticsMethod = (
  brandId: number,
  logisticsId: number,
  data: UpdateLogisticsMethod
) =>
  axios.post(`/api/brands/${brandId}/logistics_methods/${logisticsId}`, data);

export const fetchLogisticsCountries = (brandId: number) =>
  axios.get<LogisticsCountry[]>(`/api/brands/${brandId}/logistics/countries`);

export const fetchDeliveries = () => axios.get<Delivery[]>(`/api/deliveries`);
