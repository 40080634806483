import axios from 'axios';

import {
  Order,
  OrderEdit,
  OrdersActions,
  OrdersAction,
  OrderListItem,
  ShoplineOrderListItem,
  LineOrderListItem,
  WarehouseLogisticsMethod,
  LogisticsTypes,
} from '../types';

interface OrderResponse {
  total: number;
  items: Order[];
}

interface OrderListResponse {
  items: OrderListItem[];
}

interface ShoplineOrderListResponse {
  items: ShoplineOrderListItem[];
}

interface LineOrderListResponse {
  items: LineOrderListItem[];
}

export const fetchCancelledOrders = (brandId: number) =>
  axios.get<OrderResponse>(`/api/brands/${brandId}/orders/cancelled`);

export const fetchReundingOrders = (brandId: number) =>
  axios.get<OrderResponse>(`/api/brands/${brandId}/orders/refunding`);

export const fetchLogisticsFailedOrders = (brandId: number) =>
  axios.get<OrderResponse>(`/api/brands/${brandId}/orders/delivery-failed`);

export const fetchLogisticsFailedOrdersByBi = (brandId: number) =>
  axios.get<OrderResponse>(`/api/brands/${brandId}/orders/bi/delivery-failed`);

export const fetchLogisticsReversedOrders = (brandId: number) =>
  axios.get<OrderResponse>(`/api/brands/${brandId}/orders/delivery-reversed`);

export const fetchVoidingInvoicesOrders = (brandId: number) =>
  axios.get<OrderResponse>(`/api/brands/${brandId}/orders/voiding-invoices`);

export const fetchOrders = (brandId: number) =>
  axios.get<OrderListResponse>(`/api/brands/${brandId}/orders`);

export const fetchShoplineOrders = (brandId: number) =>
  axios.get<ShoplineOrderListResponse>(
    `/api/shopline/brands/${brandId}/orders`
  );

export const fetchLineOrders = (brandId: number) =>
  axios.get<LineOrderListResponse>(`/api/line/brands/${brandId}/orders`);

export const fetchOrder = (brandId: number, orderNumber: string) =>
  axios.get<OrderEdit>(`/api/brands/${brandId}/orders/${orderNumber}`);

export const fetchOrderActions = (brandId: number, orderNumber: string) =>
  axios.get<OrdersActions>(
    `/api/brands/${brandId}/orders/${orderNumber}/actions`
  );

export const updateOrderActions = (
  brandId: number,
  orderNumber: string,
  values: OrdersAction
) =>
  axios.post<OrderEdit>(
    `/api/brands/${brandId}/orders/${orderNumber}/actions`,
    values
  );

export const updateOrderComment = (
  brandId: number,
  orderNumber: string,
  comment: { content: string }
) =>
  axios.post(`/api/brands/${brandId}/orders/${orderNumber}/comments`, comment);

type OrderStatus =
  | 'pending'
  | 'confirmed'
  | 'completed'
  | 'cancelling'
  | 'cancelled'
  | 'invalid';

interface OrderStatusResponse {
  number: string;
  status: OrderStatus;
  updated_at: string;
}

export const updateOrderStatus = (
  brandId: number,
  orderNumber: string,
  status: string
) =>
  axios.patch<OrderStatusResponse>(
    `/api/brands/${brandId}/orders/${orderNumber}/order_status`,
    { status }
  );

export const fetchWarehouseLogisticsMethods = (
  brandId: number,
  warehouseId: string
) =>
  axios.get<WarehouseLogisticsMethod[]>(
    `/api/brands/${brandId}/warehouses/${warehouseId}/logistics_methods`
  );

export const updateOrderLogisticsRecipient = (
  brandId: number,
  orderNumber: string,
  recipient: LogisticsTypes
) =>
  axios.post(
    `/api/brands/${brandId}/orders/${orderNumber}/logistics/recipient`,
    recipient
  );

interface OrderStatusParameter {
  logistics_method_id: number;
  country_code: string;
  recipient: LogisticsTypes;
}

export const updateOrderLogistics = (
  brandId: number,
  orderNumber: string,
  logistics: OrderStatusParameter
) =>
  axios.post(
    `/api/brands/${brandId}/orders/${orderNumber}/logistics`,
    logistics
  );

export const updatePaymentStatus = (
  brandId: number,
  orderNumber: string,
  status: string
) =>
  axios.patch(`/api/brands/${brandId}/orders/${orderNumber}/payment_status`, {
    status,
  });

export const updateLogisticsStatus = (
  brandId: number,
  orderNumber: string,
  status: string
) =>
  axios.patch(`/api/brands/${brandId}/orders/${orderNumber}/logistics_status`, {
    status,
  });

export const updateInvoiceStatus = (
  brandId: number,
  orderNumber: string,
  status: string
) =>
  axios.patch(`/api/brands/${brandId}/orders/${orderNumber}/invoice_status`, {
    status,
  });
