import React from 'react';

import { history } from '../../../../history';
import {
  SidebarGroupItem,
  SidebarItemTypes,
  SidebarItem,
} from '../../../../types/sidebar';
import SideMenuGroup from './SideMenuGroup';
import SideMenuGroupHeader from './SideMenuGroupHeader';
import SideMenuItem from './SideMenuItem';

interface IProps {
  activePath: any;
  collapsedMenuPaths: any;
  config: SidebarItemTypes[];
  handleActiveItem: any;
  activeItemState: any;
  handleSidebarMouseEnter: any;
  hoverIndex: any;
  currentUser: any;
  toggleMenu: any;
  deviceWidth: any;
}

interface IState {
  activeGroups: any;
  currentActiveGroup: any;
  tempArr: string[];
}

class SideMenuContent extends React.Component<IProps, IState> {
  state: IState = {
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
  };

  parentArr: number[];
  collapsedPath?: string | null;

  constructor(props: IProps) {
    super(props);

    this.parentArr = [];
    this.collapsedPath = null;
  }
  redirectUnauthorized = () => {
    history.push('/misc/not-authorized');
  };

  // TODO: remove any type
  handleGroupClick = (id: string, parent: any, type = '') => {
    let open_group: string[] = this.state.activeGroups;
    let active_group = this.state.currentActiveGroup;
    let temp_arr: string[] = this.state.tempArr;
    // Active Group to apply sidebar-group-active class
    if (type === 'item' && parent === null) {
      active_group = [];
      temp_arr = [];
    } else if (type === 'item' && parent !== null) {
      active_group = [];
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
      } else {
        temp_arr = [];
        temp_arr.push(parent);
      }
      active_group = temp_arr.slice(0);
    } else if (type === 'collapse' && parent === null) {
      temp_arr = [];
      temp_arr.push(id);
    } else if (type === 'collapse' && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0);
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
      } else {
        temp_arr.push(id);
      }
    } else {
      temp_arr = [];
    }

    if (type === 'collapse') {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function (obj) {
          return active_group.indexOf(obj) === -1;
        });
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function (obj) {
            return !temp.includes(obj);
          });
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0);
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id);
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1);
      }
    }
    if (type === 'item') {
      open_group = active_group.slice(0);
    }

    this.setState({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group,
    });
  };

  // TODO: remove any type
  initRender = (parentArr: any) => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
    });
  };

  componentDidMount() {
    this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.activePath !== this.props.activePath) {
      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      );
    }
  }

  renderGroupItem(item: SidebarGroupItem) {
    return (
      <SideMenuItem
        key={item.id}
        id={item.id}
        icon={item.icon}
        title={item.title}
        group={true}
        navLink={item.navLink}
        newTab={item.newTab}
        badgeText={item.badgeText}
        badgeColor={item.badge}
        open={this.state.activeGroups.includes(item.id)}
        hover={this.props.hoverIndex === item.id}
        active={
          this.props.activeItemState === item.navLink ||
          (item.parentOf != null &&
            item.parentOf.includes(this.props.activeItemState))
        }
        groupActive={this.state.currentActiveGroup.includes(item.id)}
        disabled={item.disabled}
        onItemClick={(e) => {
          e.stopPropagation();
          this.handleGroupClick(item.id, null, item.type);
        }}
        onLinkClick={(e) => {
          e.preventDefault();
        }}
        onMouseEnter={() => {
          this.props.handleSidebarMouseEnter(item.id);
        }}
        onMouseLeave={() => {
          this.props.handleSidebarMouseEnter(item.id);
        }}
      >
        <SideMenuGroup
          group={item}
          handleGroupClick={this.handleGroupClick}
          activeGroup={this.state.activeGroups}
          handleActiveItem={this.props.handleActiveItem}
          activeItemState={this.props.activeItemState}
          handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
          activePath={this.props.activePath}
          hoverIndex={this.props.hoverIndex}
          parentArr={this.parentArr}
          currentActiveGroup={this.state.currentActiveGroup}
          currentUser={this.props.currentUser}
          redirectUnauthorized={this.redirectUnauthorized}
          collapsedMenuPaths={this.props.collapsedMenuPaths}
          toggleMenu={this.props.toggleMenu}
          deviceWidth={this.props.deviceWidth}
        />
      </SideMenuItem>
    );
  }

  renderNormalItem(item: SidebarItem) {
    return (
      <SideMenuItem
        key={item.id}
        id={item.id}
        icon={item.icon}
        title={item.title}
        navLink={item.navLink}
        newTab={item.newTab}
        badgeText={item.badgeText}
        badgeColor={item.badge}
        open={this.state.activeGroups.includes(item.id)}
        hover={this.props.hoverIndex === item.id}
        active={
          this.props.activeItemState === item.navLink ||
          (item.parentOf != null &&
            item.parentOf.includes(this.props.activeItemState))
        }
        groupActive={this.state.currentActiveGroup.includes(item.id)}
        disabled={item.disabled}
        onItemClick={(e) => {
          e.stopPropagation();
          this.props.handleActiveItem(item.navLink);
          this.handleGroupClick(item.id, null, item.type);
          if (this.props.deviceWidth <= 1200) {
            this.props.toggleMenu();
          }
        }}
        onMouseEnter={() => {
          this.props.handleSidebarMouseEnter(item.id);
        }}
        onMouseLeave={() => {
          this.props.handleSidebarMouseEnter(item.id);
        }}
      />
    );
  }

  renderItem(item: SidebarItemTypes) {
    if (item.type === 'groupHeader') {
      return <SideMenuGroupHeader key={item.id} title={item.groupTitle} />;
    }
    const renderItem =
      item.type === 'collapse'
        ? this.renderGroupItem(item)
        : this.renderNormalItem(item);

    if (
      item.navLink &&
      item.collapsed !== undefined &&
      item.collapsed === true
    ) {
      this.collapsedPath = item.navLink;
      this.props.collapsedMenuPaths(item.navLink);
    }

    if (
      item.type === 'collapse' ||
      (item.type === 'item' &&
        item.permissions &&
        item.permissions.includes(this.props.currentUser)) ||
      item.permissions === undefined
    ) {
      return renderItem;
    } else if (
      item.type === 'item' &&
      item.navLink === this.props.activePath &&
      !item.permissions.includes(this.props.currentUser)
    ) {
      return this.redirectUnauthorized();
    }
  }

  render() {
    const { config } = this.props;
    const menuItems = config.map((item) => {
      return this.renderItem(item);
    });
    return <>{menuItems}</>;
  }
}
export default SideMenuContent;
