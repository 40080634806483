import axios from 'axios';
import { getYear } from 'date-fns';
import {
  BrandStatistics,
  StatisticsMetric,
  TotalStatisticsMetric,
  SalesGoal,
  TopProduct,
} from 'types';
import { getRealMonth } from 'utility/date';

export const fetchBrandStatistics = (startDate: Date, endDate: Date) =>
  axios.get<BrandStatistics[]>('/api/statistics/brands', {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchSalesAmount = (startDate: Date, endDate: Date) =>
  axios.get<StatisticsMetric>(`/api/statistics/amount`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchSalesOrders = (startDate: Date, endDate: Date) =>
  axios.get<StatisticsMetric>(`/api/statistics/orders`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandRevenueStatistics = (
  brandId: number,
  currentMonth: Date
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/brands/${brandId}/statistics/revenue`,
    {
      params: {
        year: getYear(currentMonth),
        month: getRealMonth(currentMonth),
        timezone_offset: 8 * 3600,
      },
    }
  );

export const fetchBrandSalesGoal = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<SalesGoal>(`/api/brands/${brandId}/statistics/sales-goal`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandSalesAmount = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(`/api/brands/${brandId}/statistics/amount`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandSalesOrders = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(`/api/brands/${brandId}/statistics/orders`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandECSkuStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(`/api/brands/${brandId}/statistics/ec_skus`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandSkuStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(`/api/brands/${brandId}/statistics/skus`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandTopProducts = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TopProduct[]>(`/api/brands/${brandId}/statistics/top-products`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandCustomerCount = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(
    `/api/brands/${brandId}/statistics/customer-count`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchBrandStoreSalesStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/brands/${brandId}/statistics/store-sales`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchBrandOrderCountStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/brands/${brandId}/statistics/order-count`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchBrandEmailCounts = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(`/api/brands/${brandId}/statistics/email_count`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandEmailListCounts = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(
    `/api/brands/${brandId}/statistics/email_list_count`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchBrandSalesGoalAll = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<SalesGoal>(`/api/brands/${brandId}/statistics/sales-goal-all`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandRevenueAllStatistics = (
  brandId: number,
  currentMonth: Date
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/brands/${brandId}/statistics/revenue-all`,
    {
      params: {
        year: getYear(currentMonth),
        month: getRealMonth(currentMonth),
        timezone_offset: 8 * 3600,
      },
    }
  );

export const fetchBrandSalesOrdersAll = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(`/api/brands/${brandId}/statistics/orders-all`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandSalesAmountAll = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(`/api/brands/${brandId}/statistics/amount-all`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

export const fetchBrandOrderHourCountStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/brands/${brandId}/statistics/order-hour-count`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchBrandDistributionStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<StatisticsMetric>(
    `/api/brands/${brandId}/statistics/order_distribution_sales`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

export const fetchBrandOrderDistributionSalesStatistics = (
  brandId: number,
  startDate: string,
  endDate: string
) =>
  axios.get<TotalStatisticsMetric>(
    `/api/brands/${brandId}/statistics/order_all_and_distribution_sales`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  );
