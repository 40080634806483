import React from 'react';

import classnames from 'classnames';
import { ArrowUp } from 'react-feather';
// @ts-ignore
import ScrollToTop from 'react-scroll-up';
import { Button } from 'reactstrap';
import { FooterType } from 'redux/customizer/types';

interface IProps {
  footerType: FooterType;
  hideScrollToTop: boolean;
}

const Footer: React.FC<IProps> = ({ footerType, hideScrollToTop }) => {
  let footerTypeArr = ['sticky', 'static', 'hidden'];
  return (
    <footer
      className={classnames('footer footer-light', {
        'footer-static':
          footerType === 'static' || !footerTypeArr.includes(footerType),
        'd-none': footerType === 'hidden',
      })}
    >
      <p className="text-center">
        COPYRIGHT © {new Date().getFullYear()}
        <a href="https://wowfood.cc" target="_blank" rel="noopener noreferrer">
          Bicome,
        </a>
        All rights reserved
      </p>
      {hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
    </footer>
  );
};

export default Footer;
