import React from 'react';

import { connect } from 'react-redux';
import { changeBrand } from 'redux/actions';
import { RootState } from 'redux/reducers';
import { BrandItem } from 'types';

import SideMenuBrandHeader from './SideMenuBrandHeader';

interface IProps {
  brandId: number;
  brands: BrandItem[];
  changeBrand: typeof changeBrand;
}

class SideMenuBrand extends React.Component<IProps> {
  render() {
    const { brandId, brands, changeBrand } = this.props;
    if (brands.length === 0) {
      return null;
    }
    return (
      <SideMenuBrandHeader
        brandId={brandId}
        brands={brands}
        onBrandChange={(brandId) => {
          changeBrand(brandId, brands);
        }}
      />
    );
  }
}

const mapStateToProps = ({ sidebar: { brandId, brands } }: RootState) => ({
  brandId,
  brands,
});

export default connect(mapStateToProps, { changeBrand })(SideMenuBrand);
