export const GET_TINYMCE = 'GET_TINYMCE';

interface GetTinyMCEAction {
  type: typeof GET_TINYMCE;
  payload: {
    key: string;
  };
}

export type PluginsActions = GetTinyMCEAction;
