import axios from 'axios';

import {
  Coupon,
  CouponCampaign,
  CreateCouponCampaign,
  UpdateCouponCampaign,
} from '../types';

export const fetchCoupons = (brandId: number) =>
  axios.get<Coupon[]>(`/api/brands/${brandId}/coupons`);

export const fetchCoupon = (brandId: number, couponId: number) =>
  axios.get<CouponCampaign>(`/api/brands/${brandId}/coupons/${couponId}`);

export const createCoupon = (brandId: number, formData: CreateCouponCampaign) =>
  axios.post(`/api/brands/${brandId}/coupons`, formData);

export const updateCoupon = (
  brandId: number,
  couponId: number,
  formData: UpdateCouponCampaign
) => axios.post(`/api/brands/${brandId}/coupons/${couponId}`, formData);

export const createCouponCodes = (
  brandId: number,
  couponId: number,
  codes: string[]
) => axios.post(`/api/brands/${brandId}/coupons/${couponId}/codes`, codes);

export const updateCouponProducts = (
  brandId: number,
  couponId: number,
  products: number[]
) =>
  axios.post(`/api/brands/${brandId}/coupons/${couponId}/products`, products);
