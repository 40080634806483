import axios from 'axios';
import { ProductSchedule } from 'types';

export const updateProductSchedules = (
  brandId: number,
  productId: number,
  param: ProductSchedule
) => {
  return axios.post<ProductSchedule>(
    `/api/brands/${brandId}/products/${productId}/schedules`,
    param
  );
};
