import { BrandItem, SidebarItemTypes } from 'types';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_ERROR = 'AUTHENTICATE_ERROR';
export const CHANGE_ROLE = 'CHANGE_ROLE';

interface LoginAction {
  type: typeof LOGIN;
  payload: {
    token: string;
    profile: {
      name: string;
      email: string;
    };
    freshchat: {
      restore_id?: string;
    };
  };
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface AuthenticateAction {
  type: typeof AUTHENTICATE;
  payload: {
    brands: BrandItem[];
    globalMenu: SidebarItemTypes[];
    brandsMenu: SidebarItemTypes[];
  };
}

interface AuthenticateErrorAction {
  type: typeof AUTHENTICATE_ERROR;
}

interface ChangeRoleAction {
  type: typeof CHANGE_ROLE;
  role: string;
}

export type AuthActionTypes =
  | LoginAction
  | LogoutAction
  | AuthenticateAction
  | AuthenticateErrorAction
  | ChangeRoleAction;
