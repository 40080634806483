import { createStore, applyMiddleware, compose } from 'redux';
import createDebounce from 'redux-debounced';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { isDev } from '../utility/env';
import { addAxiosAuthInterceptor } from './authorization';
import migrate from './migrations';
import rootReducer from './reducers';

const middlewares = [thunk, createDebounce()];
if (isDev()) {
  middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  version: '0',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  migrate,
};

export const store = createStore(
  persistReducer(persistConfig, rootReducer),
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

addAxiosAuthInterceptor(store);

export const persistor = persistStore(store);
