import axios from 'axios';
import {
  BulkOrderResponse,
  BulkBindCouponResponse,
  BulkProductShippingTimeResponse,
} from 'types';

export const updateBulkOrder = (
  brandId: number,
  action: string,
  data: FormData
) =>
  axios.post<BulkOrderResponse[]>(
    `/api/brands/${brandId}/bulks/orders/${action}`,
    data,
    {
      headers: {
        'Content-Type': 'text/csv',
      },
    }
  );

export const updateBulkBindCoupon = (brandId: number, data: FormData) =>
  axios.post<BulkBindCouponResponse[]>(
    `/api/brands/${brandId}/bulks/coupons/bind_coupons`,
    data,
    {
      headers: {
        'Content-Type': 'text/csv',
      },
    }
  );

export const updateBulkSpOrder = (
  brandId: number,
  action: string,
  data: FormData
) =>
  axios.post<BulkOrderResponse[]>(
    `/api/brands/${brandId}/bulks/sp_orders/${action}`,
    data,
    {
      headers: {
        'Content-Type': 'text/csv',
      },
    }
  );

export const updateBulkProductShippingTime = (
  brandId: number,
  action: string,
  data: FormData
) =>
  axios.post<BulkProductShippingTimeResponse[]>(
    `/api/brands/${brandId}/bulks/product_shipping_time/${action}`,
    data,
    {
      headers: {
        'Content-Type': 'text/csv',
      },
    }
  );
