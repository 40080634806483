import axios from 'axios';
import { SaveProductImage } from 'types';

export const updateProductImages = (
  brandId: number,
  productId: number,
  data: FormData,
  type: string,
  extension: string
) => {
  return axios({
    method: 'post',
    url: `/api/brands/${brandId}/products/${productId}/images`,
    data,
    headers: {
      'Mime-Type': type,
      Accept: 'application/json',
      Extension: extension,
    },
  });
};

export const updateProductImageKind = (
  brandId: number,
  productId: number,
  imageId: number,
  newKind: string | null
) => {
  return axios.patch(
    `/api/brands/${brandId}/products/${productId}/images/${imageId}`,
    { kind: newKind }
  );
};

export const updateProductImage = (
  brandId: number,
  productId: number,
  imageId: number,
  formData: SaveProductImage
) => {
  return axios.patch(
    `/api/brands/${brandId}/products/${productId}/images/${imageId}`,
    formData
  );
};
