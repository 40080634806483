// import themeConfig from '../../configs/themeConfig';
import {
  CHANGE_FOOTER_TYPE,
  CHANGE_MENU_COLOR,
  CHANGE_MODE,
  CHANGE_NAVBAR_COLOR,
  CHANGE_NAVBAR_TYPE,
  COLLAPSE_SIDEBAR,
  HIDE_SCROLL_TO_TOP,
} from './types';
import {
  CustomizerActionTypes,
  Direction,
  FooterType,
  MenuTheme,
  NavbarColor,
  NavbarType,
  ThemeStyle,
} from './types';

export interface CustomizerState {
  // options[String]: "vertical"(default)
  layout: 'vertical';

  // options[String]: 'light'(default), 'dark', 'semi-dark'
  theme: ThemeStyle;

  // options[Boolean]: true, false(default)
  sidebarCollapsed: boolean;

  // options[String]: default / primary / success / danger / info / warning / dark
  navbarColor: NavbarColor;

  // options[String]: floating(default) / static / sticky / hidden
  navbarType: NavbarType;

  // options[String]: static(default) / sticky / hidden
  footerType: FooterType;

  // options[Boolean]: true, false(default)
  disableCustomizer: boolean;

  // options[Boolean]: true, false(default)
  hideScrollToTop: boolean;

  // options[String]: primary / success / danger / info / warning / dark
  menuTheme: MenuTheme;

  // options[String] : ltr / rtl
  direction: Direction;
}

const initialState: CustomizerState = {
  layout: 'vertical', // options[String]: "vertical"(default)
  theme: 'light', // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: false, // options[Boolean]: true, false(default)
  navbarColor: 'default', // options[String]: default / primary / success / danger / info / warning / dark
  navbarType: 'floating', // options[String]: floating(default) / static / sticky / hidden
  footerType: 'static', // options[String]: static(default) / sticky / hidden
  disableCustomizer: true, // options[Boolean]: true, false(default)
  hideScrollToTop: false, // options[Boolean]: true, false(default)
  menuTheme: 'primary', // options[String]: primary / success / danger / info / warning / dark
  direction: 'ltr', // options[String] : ltr / rtl
};

const customizerReducer = (
  state = initialState,
  action: CustomizerActionTypes
): CustomizerState => {
  switch (action.type) {
    case CHANGE_MODE:
      return { ...state, theme: action.mode };
    case COLLAPSE_SIDEBAR:
      return { ...state, sidebarCollapsed: action.value };
    case CHANGE_NAVBAR_COLOR:
      return { ...state, navbarColor: action.color };
    case CHANGE_NAVBAR_TYPE:
      return { ...state, navbarType: action.style };
    case CHANGE_FOOTER_TYPE:
      return { ...state, footerType: action.style };
    case CHANGE_MENU_COLOR:
      return { ...state, menuTheme: action.style };
    case HIDE_SCROLL_TO_TOP:
      return { ...state, hideScrollToTop: action.value };
    default:
      return state;
  }
};

export default customizerReducer;
