import { combineReducers } from 'redux';

import auth from './auth/reducer';
import customizer from './customizer/reducer';
import plugins from './plugins/reducer';
import sidebar from './sidebar/reducer';

const rootReducer = combineReducers({
  auth: auth,
  customizer: customizer,
  sidebar: sidebar,
  plugins: plugins,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
