import {
  startOfMonth,
  startOfTomorrow,
  endOfMonth,
  format,
  formatISO,
  subMonths,
  parseISO,
  getMonth,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export const monthRange = () => {
  return [startOfMonth(new Date()), new Date()];
};

export const lastMonthRange = () => {
  return [
    new Date(startOfPreviousMonthISO()),
    new Date(endOfPreviousMonthISO()),
  ];
};

export const dateToISO = (
  date: number | Date = Date.now(),
  timeZone = 'Asia/Taipei'
) => {
  return formatISO(zonedTimeToUtc(date, timeZone));
};

export const dateToFormat = (
  date: number | Date = Date.now(),
  timeZone = 'Asia/Taipei'
) => {
  return format(zonedTimeToUtc(date, timeZone), 'yyyy-MM-dd h:mma');
};

export const dateToFormatYMD = (
  date: number | Date = Date.now(),
  timeZone = 'Asia/Taipei'
) => {
  return format(zonedTimeToUtc(date, timeZone), 'yyyy-MM-dd');
};

export const startOfMonthISO = (
  date: number | Date = Date.now(),
  timeZone = 'Asia/Taipei'
) => {
  return formatISO(zonedTimeToUtc(startOfMonth(date), timeZone));
};

export const startOfTomorrowISO = (timeZone = 'Asia/Taipei') => {
  return formatISO(zonedTimeToUtc(startOfTomorrow(), timeZone));
};

export const startOfPreviousMonthISO = (timeZone = 'Asia/Taipei') => {
  return formatISO(
    zonedTimeToUtc(startOfMonth(subMonths(Date.now(), 1)), timeZone)
  );
};

export const endOfPreviousMonthISO = (timeZone = 'Asia/Taipei') => {
  return formatISO(
    zonedTimeToUtc(endOfMonth(subMonths(Date.now(), 1)), timeZone)
  );
};

export const normalizeISO = (iso: string) => {
  return format(parseISO(iso), 'yyyy-MM-dd h:mma');
};

export const startOfLastMonth = () => {
  return startOfMonth(subMonths(Date.now(), 1));
};

export const startOfCurrentMonth = () => {
  return startOfMonth(Date.now());
};

export const getRealMonth = (date: number | Date) => {
  return getMonth(date) + 1;
};
