import React from 'react';

import classnames from 'classnames';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import FeathterIcon from '../../../../components/general/FeatherIcon';

interface IProps {
  id: string;
  icon?: string;
  title?: string;
  group?: boolean;
  navLink?: string;
  newTab?: boolean;
  open: boolean;
  hover: boolean;
  active: boolean;
  groupActive: boolean;
  disabled?: boolean;
  badgeText?: string;
  badgeColor?: string;
  onItemClick?: (e: React.MouseEvent) => void;
  onLinkClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}
const SideMenuItem: React.FC<IProps> = ({
  id,
  icon,
  title,
  group,
  navLink,
  newTab,
  open,
  hover,
  active,
  groupActive,
  disabled,
  badgeText,
  badgeColor,
  children,
  onItemClick,
  onLinkClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <li
      className={classnames('nav-item', {
        open,
        hover,
        active,
        disabled,
        'has-sub': group,
        'sidebar-group-active': groupActive,
      })}
      key={id}
      onClick={onItemClick}
    >
      <Link
        to={navLink ?? ''}
        href=""
        className={`d-flex ${
          badgeText ? 'justify-content-between' : 'justify-content-start'
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        key={id}
        onClick={onLinkClick}
        target={newTab ? '_blank' : '_self'}
      >
        <div className="menu-text">
          <FeathterIcon icon={icon} size="20" />
          <span className="menu-item menu-title">{title}</span>
        </div>

        {badgeColor && (
          <div className="menu-badge">
            <Badge color={badgeColor} className="mr-1" pill>
              {badgeText}
            </Badge>
          </div>
        )}

        {group && <ChevronRight className="menu-toggle-icon" size={13} />}
      </Link>

      {children}
    </li>
  );
};

SideMenuItem.defaultProps = {
  group: false,
  navLink: '',
  newTab: false,
  disabled: false,
  onItemClick: () => {},
  onLinkClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default SideMenuItem;
