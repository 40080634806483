import axios from 'axios';
import { ProductLogisticsMethod } from 'types';

export const fetchProductLogisticsMethods = (
  brandId: number,
  productId: number
) => {
  return axios.get<ProductLogisticsMethod[]>(
    `/api/brands/${brandId}/products/${productId}/logistics_methods`
  );
};
