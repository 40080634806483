import axios from 'axios';

export const fetchShoplineProducts = (brandId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/products`);

export const fetchShoplineProduct = (brandId: number, productId: number) =>
  axios.get(`/api/shopline/brands/${brandId}/products/${productId}`);

export const updateShoplineProduct = (
  brandId: number,
  productId: number,
  product: any
) =>
  axios.post(`/api/shopline/brands/${brandId}/products/${productId}`, {
    ...product,
  });

export const fetchShoplinePreorder = (brandId: number, productId: number) =>
  axios.get(
    `/api/shopline/brands/${brandId}/products/${productId}/preorder_dates`
  );

export const updateShoplinePreorder = (
  brandId: number,
  productId: number,
  order_created_at: string,
  delivery_at: string
) =>
  axios.post(
    `/api/shopline/brands/${brandId}/products/${productId}/preorder_dates`,
    {
      order_created_at,
      delivery_at,
    }
  );

export const deleteShoplinePreorder = (
  brandId: number,
  productId: number,
  preorderId: number
) =>
  axios.delete(
    `/api/shopline/brands/${brandId}/products/${productId}/preorder_dates/${preorderId}`
  );
