import axios from 'axios';
import { OrderCampaign, NewOrderCampaign } from 'types';

export const fetchOrderCampaigns = (brandId: number) =>
  axios.get<OrderCampaign[]>(`/api/brands/${brandId}/order_campaigns`);

export const fetchOrderCampaign = (brandId: number, orderCampaignId: number) =>
  axios.get<OrderCampaign>(
    `/api/brands/${brandId}/order_campaigns/${orderCampaignId}`
  );

export const createOrderCampaign = (
  brandId: number,
  formData: NewOrderCampaign
) => axios.post(`/api/brands/${brandId}/order_campaigns`, formData);

export const updateOrderCampaign = (
  brandId: number,
  orderCampaignId: number,
  formData: NewOrderCampaign
) =>
  axios.post(
    `/api/brands/${brandId}/order_campaigns/${orderCampaignId}`,
    formData
  );

export const deleteOrderCampaign = (brandId: number, orderCampaignId: number) =>
  axios.delete(`/api/brands/${brandId}/order_campaigns/${orderCampaignId}`);
