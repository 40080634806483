import axios from 'axios';
import { ProductCampaign, NewProductCampaign } from 'types';

export const fetchProductCampaigns = (brandId: number) =>
  axios.get<ProductCampaign[]>(`/api/brands/${brandId}/product_campaigns`);

export const fetchProductCampaign = (
  brandId: number,
  productCampaignId: number
) =>
  axios.get<ProductCampaign>(
    `/api/brands/${brandId}/product_campaigns/${productCampaignId}`
  );

export const createProductCampaign = (
  brandId: number,
  formData: NewProductCampaign
) => axios.post(`/api/brands/${brandId}/product_campaigns`, formData);

export const updateProductCampaign = (
  brandId: number,
  productCampaignId: number,
  formData: NewProductCampaign
) =>
  axios.post(
    `/api/brands/${brandId}/product_campaigns/${productCampaignId}`,
    formData
  );

export const deleteProductCampaign = (
  brandId: number,
  productCampaignId: number
) =>
  axios.delete(`/api/brands/${brandId}/product_campaigns/${productCampaignId}`);
